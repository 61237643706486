import { BusinessTrainingType, FavBusinessTraining } from "../models/favBusinessTraining";
import { Training, TrainingType } from "../models/training";
import { APIVersion, AuthenticatedFileRequest, AuthenticatedRequest, HTTPMethod } from "../requests/request";

export class API {

    /**
     * Get Trainings
     * 
     * @param type TrainingType
     * @param random 
     * @param limit
     */
    public static getTrainings(type?: TrainingType, random?: boolean, limit?: number, offset?: number): Promise<Training[]> {
        let apiReqest = new AuthenticatedRequest<Training>('/training', HTTPMethod.GET, APIVersion.v1_0, {
            type,
            random,
            limit,
            offset
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Count Trainings
     * 
     * @param type TrainingType
     */
    public static countTrainings(type?: TrainingType): Promise<number> {
        let apiReqest = new AuthenticatedRequest<number>('/training/count', HTTPMethod.GET, APIVersion.v1_0, {
            type
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Returns a specific Training
     * 
     * @param id 
     */
    public static getTraining(id: string): Promise<Training> {
        let apiReqest = new AuthenticatedRequest<Training>(`/training/${id}`, HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Upload a Video
     * 
     * @param video Video file
     */
    public static async uploadTrainingVideo(video: any): Promise<{ mp4: string }> {
        let apiReqest = new AuthenticatedFileRequest<{
            mp4: string
        }>('/training/videoupload', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            video
        });
        const response = await apiReqest.send();
        return response.data;
    }

    /**
     * Upload a Picture
     * 
     * @param image Image file
     */
    public static async uploadTrainingImage(image: any): Promise<string> {
        let apiReqest = new AuthenticatedFileRequest<string>('/training/imageupload', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            image
        });
        const response = await apiReqest.send();
        return response.data;
    }

    /**
     * Upload an Audio
     * 
     * @param audio Audio file
     */
    public static uploadTrainingAudio(audio: any): Promise<{m4a: string}> {
        let apiReqest = new AuthenticatedFileRequest<{m4a: string}>('/training/audioupload', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            audio
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Add a training
     * 
     * @param name 
     * @param video_url 
     * @param type 
     * @param points 
     * @param video_thumbnail 
     */
    public static async addTraining(name: string, video_url: string, type: TrainingType, points: number, video_thumbnail?: string): Promise<Training> {
        let apiReqest = new AuthenticatedRequest<Training>('/training', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            name,
            video_url,
            video_thumbnail,
            type,
            points
        });
        const response = await apiReqest.send(Training);
        return <Training>response.data;
    }
    /**
     * Update a training
     * 
     * @param id 
     * @param name 
     * @param video_url 
     * @param type 
     * @param points 
     * @param video_thumbnail 
     */
    public static async updateTraining(id: string, name?: string, video_url?: string, type?: TrainingType, points?: number, video_thumbnail?: string): Promise<Training> {
        let apiReqest = new AuthenticatedRequest<Training>(`/training/${id}`, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            name,
            video_url,
            type,
            points,
            video_thumbnail
        });
        const response = await apiReqest.send(Training);
        return <Training>response.data;
    }

    /**
     * Deletes a training
     * 
     * @param id 
     */
    public static deleteTraining(id: string): Promise<{}> {
        let apiReqest = new AuthenticatedRequest<{}>(`/training/${id}`, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return <{}>response.data
        })
    }

    /**
     * Returns the favorite Business Trainings
     */
    public static getFavoriteBusinessTraining(type?: BusinessTrainingType): Promise<FavBusinessTraining[]> {
        let apiReqest = new AuthenticatedRequest<FavBusinessTraining>('/training/favorites/business', HTTPMethod.GET, APIVersion.v1_0, {
            type
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Add a business Training to favorites
     * 
     * @param name 
     * @param trainings 
     */
    public static addFavoriteBusinessTraining(name: string, type: BusinessTrainingType, trainings: string[]): Promise<FavBusinessTraining> {
        let apiReqest = new AuthenticatedRequest<FavBusinessTraining>('/training/favorites/business/add', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            name,
            type,
            trainings
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Delete a favorite Business Training
     * 
     * @param id 
     */
    public static deleteFavoriteBusinessTraining(id: string): Promise<{}> {
        let apiReqest = new AuthenticatedRequest<{}>(`/training/favorites/business/${id}`, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }
}