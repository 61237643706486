import * as React from 'react'
import { withRouter, RouteComponentProps, useLocation, Link } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { CompanyAPI } from '../../api/endpoints';
import { Company } from '../../api/models/company';
import { InviteKey } from '../../api/models/inviteKey';
import {CONTACT_ICON, ICON_KEY, ICON_LIVE, MAIL_ICON, PHONE_ICON, WEBSITE_ICON} from '../../assets';

export const CustomerDetail: React.FC = () => {
    let query = new URLSearchParams(useLocation().search)
    let customerId = query.get("customerId")
    return (
        <CustomerDetailInner key={customerId} customerId={customerId} />
    )
}

export interface CustomerDetailProps extends RouteComponentProps {
    customerId?: string | null
}

const CustomerDetailInner = withRouter(class extends React.Component<CustomerDetailProps, {
    company?: Company,
    inviteKeys?: InviteKey[]
    companyLoading: boolean,
    keysLoading: boolean,
    licenses: string
}> {

    constructor(props: CustomerDetailProps) {
        super(props);
        this.state = {
            companyLoading: true,
            keysLoading: true,
            licenses: "1"
        }
    }

    componentDidMount() {
        const { customerId } = this.props
        if (customerId) {
            this.getCompany(customerId)
            this.getInviteKeys(customerId)
        } else {
            this.setState({ companyLoading: false, keysLoading: false })
        }
    }

    getCompany(customerId: string) {
        CompanyAPI.getCompany(customerId).then((company) => {
            this.setState({ company })
        }).catch((err) => {
            /* ToDo */
        }).finally(() => {
            this.setState({ companyLoading: false })
        })
    }

    getInviteKeys(customerId: string) {
        CompanyAPI.getInviteKeys(customerId).then((inviteKeys) => {
            this.setState({ inviteKeys })
        }).catch((err) => {
            //ToDo
        }).finally(() => {
            this.setState({ keysLoading: false })
        })
    }

    render() {
        if (this.state.companyLoading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        const { company, inviteKeys } = this.state
        let activeLicenses = inviteKeys?.filter((val) => val.active)
        let inactiveLicenses = inviteKeys?.filter((val) => !val.active)
        return (
            <div className="main">
                {company ?
                    <div className="row">
                        <div className="col-12">
                            <div className="challenge-detail-card">
                                <h3>{company.name}</h3>
                                <div className="custom-row">
                                    {company.img_url && <img src={company.img_url} />}
                                    <div>
                                        <div className="info"><img src={CONTACT_ICON} />{company.contact}</div>
                                        <div className="info"><img src={MAIL_ICON} />{company.email}</div>
                                        <div className="info"><img src={WEBSITE_ICON} />{company.website}</div>
                                        <div className="info"><img src={PHONE_ICON} />{company.phone_number}</div>
                                        <div className="info"><img src={ICON_LIVE} />{company.live_eligible}</div>
                                        {/*                                         <div className="info"><img src={ICON_KEY} />{inviteKeys ? `${inviteKeys.inUse} / ${inviteKeys.total} (${inviteKeys.inactive} inaktiv)` : '-'}</div> */}
                                    </div>
                                </div>
                                <div className="edit_delete">
                                    <Link to={`/edit-customer?customerId=${company.id}`}>
                                        <i className="fa fa-edit"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {this.state.keysLoading ? <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            <ClipLoader />
                        </div>
                            :
                            this.state.inviteKeys ?
                                <>
                                    <div className="col-12">
                                        <div className="challenge-detail-card">
                                            <h4>{'Aktive Lizenzen'}</h4>
                                            {activeLicenses && activeLicenses.length > 0 ?
                                                <div className="table-responsive">
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" style={{ width: 60 }}>#</th>
                                                                <th scope="col" style={{ width: 150 }}>Code</th>
                                                                <th scope="col">User</th>
                                                                <th scope="col" style={{ width: 40 }}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {activeLicenses.sort((a, b) => a.user ? -1 : 1).map((key, index) => {
                                                                return <tr>
                                                                    <th scope="row" style={{ width: 40 }}>{index + 1}</th>
                                                                    <td style={{ width: 120 }}>{key.key}</td>
                                                                    <td>{key.user ? key.user.full_name : ''}</td>
                                                                    <td style={{ width: 40 }}>
                                                                        <i className={key.user ? 'fa fa-toggle-on' : 'fa fa-trash'} style={{ cursor: 'pointer' }} onClick={() => key.user ? this.refreshKey(key.id!, false) : this.deleteKey(key.id!)}></i>
                                                                    </td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                :
                                                <div>{"Dieses Unternehmen hat aktuell keine aktiven Lizenzen"}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="challenge-detail-card">
                                            <h4>{'Inaktive Lizenzen'}</h4>
                                            {inactiveLicenses && inactiveLicenses.length > 0 ?
                                                <div className="table-responsive">
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" style={{ width: 60 }}>#</th>
                                                                <th scope="col" style={{ width: 150 }}>Code</th>
                                                                <th scope="col">User</th>
                                                                <th scope="col" style={{ width: 40 }}></th>
                                                                <th scope="col" style={{ width: 40 }}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {inactiveLicenses.map((key, index) => {
                                                                return <tr>
                                                                    <th scope="row" style={{ width: 40 }}>{index + 1}</th>
                                                                    <td style={{ width: 120 }}>{key.key}</td>
                                                                    <td>{key.user ? key.user.full_name : ''}</td>
                                                                    <td style={{ width: 40 }}>
                                                                        <i className="fa fa-toggle-off" style={{ cursor: 'pointer' }} onClick={() => this.refreshKey(key.id!, true)}></i>
                                                                    </td>
                                                                    <td style={{ width: 40 }}>
                                                                        <i className="fa fa-trash" style={{ cursor: 'pointer' }} onClick={() => this.deleteKey(key.id!)}></i>
                                                                    </td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                :
                                                <div>{"Dieses Unternehmen hat aktuell keine inaktiven Lizenzen"}</div>
                                            }
                                        </div>
                                    </div>
                                </>
                                :
                                <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    {"Die Lizenzen können aktuell nicht geladen werden"}
                                </div>
                        }
                        <div className="col-12">
                            <div className="news_add_card">
                                <p style={{marginTop: 10}}>{`Lizenzen hinzufügen`}</p>
                                <div style={{ display: "flex", flexDirection: 'row' }}>
                                    <input
                                        type="number"
                                        placeholder=""
                                        min={1}
                                        value={this.state.licenses}
                                        onChange={(event) => {
                                            this.setState({ licenses: event.target.value })
                                        }}
                                        style={{padding: 8, width: 50}}
                                    />
                                    <div style={{height: 43, alignItems: 'center', padding: 13, display: "flex"}}>
                                        <i className="fa fa-plus fa-lg" style={{color: "#a5c7a9", cursor: "pointer"}} onClick={() => this.createLicenses()}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div>{"Das Unternehmen kann gerade nicht geladen werden."}</div>
                }

            </div>
        )
    }


    refreshKey(id: string, active: boolean) {
        CompanyAPI.refreshInviteKey(id, active).then((key) => {
            this.state.company?.id && this.getInviteKeys(this.state.company?.id)
        }).catch((err) => {
            //ToDo
        })
    }

    deleteKey(id: string) {
        CompanyAPI.deleteInviteKey(id).then((res) => {
            this.state.company?.id && this.getInviteKeys(this.state.company?.id)
        }).catch((err) => {
            //ToDo
        })
    }

    createLicenses() {
        this.state.company?.id && CompanyAPI.createInviteKeys(this.state.company.id, Number(this.state.licenses)).then((keys) => {
            this.state.company?.id && this.getInviteKeys(this.state.company?.id)
        }).catch((err) => {
            //ToDo
        })
    }

})

export default React.memo(CustomerDetail)