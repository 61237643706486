import * as React from 'react'
import { withRouter, RouteComponentProps, useLocation, Link } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { NewsAPI } from '../../api/endpoints';
import { News } from '../../api/models/news';
import { getNewsType } from '../../utils/translate';

export const NewsDetail: React.FC = () => {
    let query = new URLSearchParams(useLocation().search)
    let newsId = query.get("newsId")
    return (
        <NewsDetailInner key={newsId} newsId={newsId} />
    )
}

export interface NewsDetailProps extends RouteComponentProps {
    newsId?: string | null
}

const NewsDetailInner = withRouter(class extends React.Component<NewsDetailProps, {
    newsArticle?: News
    loading: boolean
}> {

    constructor(props: NewsDetailProps) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
        this.props.newsId ? this.getNews(this.props.newsId) : this.setState({ loading: false })
    }

    getNews(newsId: string) {
        NewsAPI.getNewsArticle(newsId).then((newsArticle) => {
            this.setState({ newsArticle })
        }).catch((err) => {
            /* ToDo */
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        if (this.state.loading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        const { newsArticle } = this.state
        return (
            <div className="main">
                {newsArticle ?
                    <div className="row">
                        <div className="col-xl-8 col-md-10 col-12">
                            <div className="challenge-detail-card">
                                <h3>{newsArticle.headline}</h3>
                                <h5>{getNewsType(newsArticle.type)}</h5>
                                {newsArticle.img_url &&
                                    <img className="lg" style={{marginRight: 0}} src={newsArticle.img_url} />
                                }
                                <div className="text">
                                    {newsArticle.text}
                                </div>
                                <div className="edit_delete">
                                    <Link to={`/edit-news?newsId=${newsArticle.id}`}>
                                        <i className="fa fa-edit"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <div>{"Der Artikel kann gerade nicht geladen werden."}</div>
                }

            </div>
        )
    }

})

export default React.memo(NewsDetail)