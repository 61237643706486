import { News, NewsType } from "../models/news";
import { APIVersion, AuthenticatedFileRequest, AuthenticatedRequest, HTTPMethod } from "../requests/request"

export class API {

    /**
     * Get the news
     */
    public static getNews(type?: NewsType, search?: string, limit?: number, offset?: number): Promise<News[]> {
        let apiReqest = new AuthenticatedRequest<News>('/news', HTTPMethod.GET, APIVersion.v1_0, {
            type,
            search,
            limit,
            offset
        });
        return apiReqest.send(News).then((response) => {
            return <News[]>response.data
        })
    }

    /**
     * Get a news article
     */
    public static getNewsArticle(id: string): Promise<News> {
        let apiReqest = new AuthenticatedRequest<News>(`/news/${id}`, HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send(News).then((response) => {
            return <News>response.data
        })
    }

    /**
     * Upload a Picture
     * 
     * @param image Image file
     */
    public static uploadNewsImage(image: any): Promise<string> {
        let apiReqest = new AuthenticatedFileRequest<string>('/news/picture', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            image
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Adds a News Article
     * 
     * @param headline 
     * @param text 
     * @param type 
     * @param img_url 
     */
    public static addNewsArticle(headline: string, text: string, type: NewsType, img_url?: string): Promise<News> {
        let apiReqest = new AuthenticatedRequest<News>('/news', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            headline,
            text,
            type,
            img_url
        });
        return apiReqest.send(News).then((response) => {
            return <News>response.data
        })
    }

    /**
     * Updates a News Article
     * 
     * @param headline 
     * @param text 
     * @param type 
     * @param img_url 
     */
    public static updateNewsArticle(id: string, headline?: string, text?: string, type?: NewsType, img_url?: string): Promise<News> {
        let apiReqest = new AuthenticatedRequest<News>(`/news/${id}`, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            headline,
            text,
            type,
            img_url
        });
        return apiReqest.send(News).then((response) => {
            return <News>response.data
        })
    }

    /**
     * Deletes a news Article
     * 
     * @param id 
     */
    public static deleteNewsArticle(id: string): Promise<{}> {
        let apiReqest = new AuthenticatedRequest<{}>(`/news/${id}`, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return <{}>response.data
        })
    }

    /**
     * Add a news Article to favorites
     * 
     * @param newsId 
     */
    public static addFavorite(newsId: string): Promise<any> {
        let apiReqest = new AuthenticatedRequest<any>(`/news/${newsId}/favorite/add`, HTTPMethod.POST, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Remove a news Article from favorites
     * 
     * @param newsId 
     */
    public static removeFavorite(newsId: string): Promise<any> {
        let apiReqest = new AuthenticatedRequest<any>(`/news/${newsId}/favorite/remove`, HTTPMethod.POST, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Get the News Favorites
     */
    public static getFavorites(): Promise<News[]> {
        let apiReqest = new AuthenticatedRequest<News[]>('/news/favorites', HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Count News
     * 
     * @param type NewsType
     */
    public static countNews(type?: NewsType): Promise<number> {
        let apiReqest = new AuthenticatedRequest<number>('/news/count', HTTPMethod.GET, APIVersion.v1_0, {
            type
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }
}