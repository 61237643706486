import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import { UserAPI } from './api/endpoints';
import { User } from './api/models/user';
import { Session } from './api/requests/request';
import { LOGO } from './assets';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { UserContextProvider } from './context/user';
import AddChallenge from './pages/Challenges/AddChallenge';
import { ChallengeDetail } from './pages/Challenges/ChallengeDetail';
import Challenges from './pages/Challenges/Challenges';
import ChangeCompany from './pages/Company/ChangeCompany';
import Company from './pages/Company/Company';
import Contributors from './pages/Contributors/Contributors';
import AddCustomer from './pages/Customers/AddCustomer';
import { CustomerDetail } from './pages/Customers/CustomerDetail';
import Customers from './pages/Customers/Customers';
import Dashboard from './pages/Dashboard';
import AddDiscount from './pages/Discounts/AddDiscount';
import { DiscountDetail } from './pages/Discounts/DiscountDetail';
import Discounts from './pages/Discounts/Discounts';
import { AddEvent } from './pages/Events/AddEvent';
import { CompanyEventDetail } from './pages/Events/EventDetail';
import Events from './pages/Events/Events';
import Login from './pages/Login';
import AddMeditation from './pages/Meditation/AddMeditation';
import Meditation from './pages/Meditation/Meditation';
import { MeditationDetail } from './pages/Meditation/MeditationDetail';
import AddNews from './pages/News/AddNews';
import News from './pages/News/News';
import NewsDetail from './pages/News/NewsDetail';
import ChangePassword from './pages/Profile/ChangePassword';
import ChangeProfile from './pages/Profile/ChangeProfile';
import Profile from './pages/Profile/Profile';
import AddRecipe from './pages/Recipes/AddRecipe';
import RecipeDetail from './pages/Recipes/RecipeDetail';
import Recipes from './pages/Recipes/Recipes';
import ResetPW from './pages/ResetPW';
import AddWorkout from './pages/Workout/AddWorkout';
import { WorkoutDetail } from './pages/Workout/WorkoutDetail';
import Workouts from './pages/Workout/Workouts';
import { USER_KEY } from './utils/constants';
import { SessionData, UserSession } from './utils/session';
import AddQr from "./pages/QR/AddQr";
import Qr from "./pages/QR/Qr";

export default class App extends React.Component<RouteComponentProps<{ page: string }>, {
    user?: User
}> {

    get userSession() {
        try {
            let cachedSession = localStorage.getItem(USER_KEY)
            if (cachedSession) {
                const session: SessionData = JSON.parse(cachedSession)
                return session
            }
        } catch (error) {

        }
    }

    constructor(props: any) {
        super(props);
        this.state = {

        }
        try {
            const session = this.userSession
            if (session) {
                Session.setSession(session.device)
                this.state = {
                    ...this.state, user: session.device.User
                }
            }
        } catch (error) {

        }
    }

    componentDidMount() {
        if (this.state.user) {
            UserAPI.getUser().then((user) => {
                this.setState({ user })
            }).catch(err => {
                this.setState({ user: undefined })
                UserSession.logout()
                this.props.history.push('/login')
            })
        }
        UserSession.setUserUpdatedHandler((user) => {
            this.setState({ user })
            const session = this.userSession
            if (session) {
                session.user = user
                try {
                    localStorage.setItem(USER_KEY, JSON.stringify(session))
                } catch (error) {

                }
            }
        })
        UserSession.setSessionUpdatedHandler((session) => {
            if (session) {
                try {
                    localStorage.setItem(USER_KEY, JSON.stringify(session))
                } catch (error) {

                }
                this.setState({ user: session.user })
            } else {
                this.setState({ user: undefined })
            }
        })
    }

    private getRootComponent(page: string): [any, any?, string?] {
        switch (page) {
            case undefined:
            case '':
            case 'login':
                return [Login]
            case 'forgot-password':
                return [ResetPW]
            default:
                return [Redirect as any]
        }
    }

    private getMainComponent(page: string): [any, any?, string?] {
        switch (this.state.user?.role) {
            case 'admin':
                switch (page) {
                    case undefined:
                    case '':
                    case 'dashboard':
                        return [Dashboard, { userRole: 'admin' }, 'Dashboard']
                    case 'profile':
                        return [Profile, undefined, 'Profil']
                    case 'change-profile':
                        return [ChangeProfile, undefined, 'Profil ändern']
                    case 'change-password':
                        return [ChangePassword, undefined, 'Passwort ändern']
                    case 'company':
                        return [Company, undefined, 'Unternehmen']
                    case 'change-company':
                        return [ChangeCompany, undefined, 'Unternehmen ändern']
                    case 'contributors':
                        return [Contributors, undefined, 'Mitarbeiter']
                    case 'challenges':
                        return [Challenges, undefined, 'Challenges']
                    case 'add-challenge':
                        return [AddChallenge, undefined, 'Challenge hinzufügen']
                    case 'edit-challenge':
                        return [AddChallenge, undefined, 'Challenge ändern']
                    case 'challenge-detail':
                        return [ChallengeDetail, undefined, 'Challenge']
                    case 'events':
                        return [Events, undefined, 'Events']
                    case 'add-events':
                        return [AddEvent, undefined, 'Events hinzufügen']
                    case 'company-events':
                        return [Events, undefined, 'Events']
                    case 'add-company-event':
                        return [AddEvent, undefined, 'Event hinzufügen']
                    case 'edit-company-event':
                        return [AddEvent, undefined, 'Event ändern']
                    case 'company-event-detail':
                        return [CompanyEventDetail, undefined, 'Event']
                    default:
                        return [Redirect as any, { to: '/' }]
                }
            case 'manager':
                switch (page) {
                    case undefined:
                    case '':
                    case 'dashboard':
                        return [Dashboard, { userRole: 'manager' }, 'Dashboard']
                    case 'profile':
                        return [Profile, undefined, 'Profil']
                    case 'change-profile':
                        return [ChangeProfile, undefined, 'Profil ändern']
                    case 'change-password':
                        return [ChangePassword, undefined, 'Passwort ändern']
                    case 'customers':
                        return [Customers, undefined, 'Kunden']
                    case 'add-customer':
                        return [AddCustomer, undefined, 'Kunden hinzufügen']
                    case 'edit-customer':
                        return [AddCustomer, undefined, 'Kunden ändern']
                    case 'customer-detail':
                        return [CustomerDetail, undefined, 'Kunde']
                    case 'workout':
                        return [Workouts, undefined, 'Workouts']
                    case 'add-workout':
                        return [AddWorkout, undefined, 'Workout hinzufügen']
                    case 'edit-workout':
                        return [AddWorkout, undefined, 'Workout ändern']
                    case 'workout-detail':
                        return [WorkoutDetail, undefined, 'Workout']
                    case 'news':
                        return [News, undefined, 'News']
                    case 'add-news':
                        return [AddNews, undefined, 'News hinzufügen']
                    case 'edit-news':
                        return [AddNews, undefined, 'News ändern']
                    case 'news-detail':
                        return [NewsDetail, undefined, 'News']
                    case 'recipes':
                        return [Recipes, undefined, 'Rezepte']
                    case 'add-recipe':
                        return [AddRecipe, undefined, 'Rezept hinzufügen']
                    case 'edit-recipe':
                        return [AddRecipe, undefined, 'Rezept ändern']
                    case 'recipe-detail':
                        return [RecipeDetail, undefined, 'Rezept']
                    case 'meditation':
                        return [Meditation, undefined, 'Meditation']
                    case 'add-meditation':
                        return [AddMeditation, undefined, 'Meditation hinzufügen']
                    case 'edit-meditation':
                        return [AddMeditation, undefined, 'Meditation ändern']
                    case 'meditation-detail':
                        return [MeditationDetail, undefined, 'Meditation']
                    case 'discounts':
                        return [Discounts, undefined, 'Rabatte']
                    case 'add-discount':
                        return [AddDiscount, undefined, 'Rabatt hinzufügen']
                    case 'edit-discount':
                        return [AddDiscount, undefined, 'Rabatt ändern']
                    case 'discount-detail':
                        return [DiscountDetail, undefined, 'Rabatt']
                    case 'challenges':
                        return [Challenges, undefined, 'Challenges']
                    case 'add-challenge':
                        return [AddChallenge, undefined, 'Challenge hinzufügen']
                    case 'edit-challenge':
                        return [AddChallenge, undefined, 'Challenge ändern']
                    case 'challenge-detail':
                        return [ChallengeDetail, undefined, 'Challenge']
                    case 'qr':
                        return [Qr, undefined, "Partnerübersicht"]
                    case 'add-qr':
                        return [AddQr, undefined, "Partner Erstellen"]
                    case 'edit-partner':
                        return [AddQr, undefined, "Partner editieren"]
                    default:
                        return [Redirect as any, { to: '/' }]
                }
            default:
                return [Redirect as any, { to: '/' }]
        }

    }

    render() {
        const [Comp, Props = {}, title] = this.state.user ? this.getMainComponent(this.props.match.params.page) : this.getRootComponent(this.props.match.params.page)
        if (!this.state.user) {
            return (
                <Comp {...Props} />
            )
        }
        return (
            <UserContextProvider value={this.state.user}>
                <div className="content row">
                    <Sidebar
                        onNavItemPress={(item) => {

                        }}
                        userRole={(this.state.user!.role) as ('admin' | 'manager')}
                    />
                    <div className="rightbar col-lg-10">
                        <Header
                            title={title || ""}
                            user={this.state.user}
                        />
                        <Comp {...Props} />
                    </div>
                </div>
            </UserContextProvider>

        )
    }
}

