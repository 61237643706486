import * as React from 'react'
import { withRouter, RouteComponentProps, useLocation, Link } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { CompanyAPI, NewsAPI, RecipesAPI } from '../../api/endpoints';
import { Company } from '../../api/models/company';
import { News } from '../../api/models/news';
import { Recipe } from '../../api/models/recipe';
import { RecipeIngredient } from '../../api/models/recipeIngredient';
import { RecipeStep } from '../../api/models/recipeStep';
import { CONTACT_ICON, MAIL_ICON, PHONE_ICON, WEBSITE_ICON } from '../../assets';
import { getNewsType } from '../../utils/translate';

export const RecipeDetail: React.FC = () => {
    let query = new URLSearchParams(useLocation().search)
    let recipeId = query.get("recipeId")
    return (
        <RecipeDetailInner key={recipeId} recipeId={recipeId} />
    )
}

export interface RecipeDetailProps extends RouteComponentProps {
    recipeId?: string | null
}

const RecipeDetailInner = withRouter(class extends React.Component<RecipeDetailProps, {
    recipe?: Recipe
    loading: boolean
}> {

    constructor(props: RecipeDetailProps) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
        this.props.recipeId ? this.getRecipe(this.props.recipeId) : this.setState({ loading: false })
    }

    getRecipe(recipeId: string) {
        RecipesAPI.getRecipe(recipeId).then((recipe) => {
            this.setState({ recipe })
        }).catch((err) => {
            /* ToDo */
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        if (this.state.loading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        const { recipe } = this.state
        return (
            <div className="main">
                {recipe ?
                    <div className="row">
                        <div className="col-xl-8 col-md-10 col-12">
                            <div className="challenge-detail-card">
                                <h3>{recipe.name}</h3>
                                {recipe.img_url &&
                                    <img className="lg" style={{ marginRight: 0 }} src={recipe.img_url} />
                                }
                                <div className="text">
                                    {recipe.ingredients.map((ingredient, index) => {
                                        return this.renderIngredient(ingredient, index)
                                    })}
                                </div>
                                <div className="text">
                                    {recipe.steps.sort((a, b) => a.step < b.step ? -1 : a.step > b.step ? 1 : 0).map((step, index) => {
                                        return this.renderStep(step, index)
                                    })}
                                </div>
                                <div className="edit_delete">
                                    <Link to={`/edit-recipe?recipeId=${recipe.id}`}>
                                        <i className="fa fa-edit"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div>{"Das Rezept kann gerade nicht geladen werden."}</div>
                }

            </div>
        )
    }

    renderIngredient(ingredient: RecipeIngredient, index: number) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', padding: "3px 0px" }} key={`ingredient_${index}`}>
                <span className="list" style={{ width: 50 }}>{ingredient.amount}</span>
                <span className="list" style={{ width: 60 }}>{ingredient.unit}</span>
                <span className="list">{ingredient.name}</span>
            </div>
        )
    }

    renderStep(step: RecipeStep, index: number) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', padding: "3px 0px" }} key={`ingredient_${index}`}>
                <div style={{ width: 30 }}>
                    <span className="list" style={{ width: 30 }}>{`${step.step + 1}.`}</span>
                </div>
                <span className="list">{step.description}</span>
            </div>
        )
    }

})

export default React.memo(RecipeDetail)