import { BaseModel } from "./base";
import { JsonProperty } from "../requests/jsonparser";
import { Recipe } from "./recipe";
import { News } from "./news";

export type UserRole = 'user' | 'admin' | 'manager'

export class User extends BaseModel {

    @JsonProperty({ type: 'string' })
    email: string;

    @JsonProperty({ type: 'string' })
    full_name: string;

    @JsonProperty({ type: 'string?' })
    profile_image_url?: string;

    @JsonProperty({ type: 'string?' })
    profile_image_thumbnail_url?: string;

    @JsonProperty({ type: 'boolean' })
    verified: boolean

    @JsonProperty({ clazz: Recipe, type: 'Recipe' })
    favorite_recipes?: Recipe[]

    @JsonProperty({ clazz: News, type: 'News' })
    favorite_news?: News[]

    @JsonProperty({ type: 'string'})
    role?: UserRole
}