import {APIVersion, AuthenticatedRequest, HTTPMethod} from "../requests/request";
import {Partner} from "../models/partner";


export class API {
    public static getAllPartners(limit?: number, offset?: number): Promise<Partner[]> {
        let apiReqest = new AuthenticatedRequest<Partner>('/partners', HTTPMethod.GET, APIVersion.v1_0, {
            limit,
            offset
        });
        console.log("api all partners")
        console.log(apiReqest);
        return apiReqest.send().then((response) => {
            console.log(response);
            return <Partner[]>response.data
        })
    }

    public static getPartner(id: string): Promise<Partner> {
        let apiReqest = new AuthenticatedRequest<Partner>(`/partner/${id}`, HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data;
        })
    }

    public static savePartner(partner: string, url: string, mail: string, phone: string, company_name: string) {
        console.log(company_name);
        let apiRequest = new AuthenticatedRequest<Partner>(`/partner/create`, HTTPMethod.POST, APIVersion.v1_0, undefined, {
            partner,
            url,
            mail,
            phone,
            company_name
        });
        return apiRequest.send(Partner).then((response) => {
            return response.data;
        })
    }

    public static deletePartner(id: string) {
        let apiRequest = new AuthenticatedRequest<Partner>(`/partner/${id}`, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiRequest.send().then((response) => {
            return response.data;
        })
    }

    public static updatePartner(id: string, partner: string, url: string, mail:string, phone: string, company_name: string) {
        let apiRequest = new AuthenticatedRequest<Partner>(`/partner/${id}`, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            partner,
            url,
            mail,
            phone,
            company_name
        });
        return apiRequest.send(Partner).then((response) => {
            return response.data;
        })
    }
}