import * as FingerprintJS from '@fingerprintjs/fingerprintjs'
import { DeviceData } from '../api/endpoints/device'

export function getDeviceData(): Promise<DeviceData> {
    return new Promise((resolve, reject) => {
        FingerprintJS.default.load().then((fp) => {
            fp.get().then((result) => {
                resolve({
                    device_uuid: result.visitorId,
                    app_version: "1.0",
                    build_version: "1",
                    debug: false,
                    language: navigator.language
                })
            })
        })
    })
}