import { BaseModel } from "./base";
import { JsonProperty } from "../requests/jsonparser";
import { RecipeStep } from "./recipeStep";
import { RecipeIngredient } from "./recipeIngredient";

export type RecipeType = 'breakfast' | 'cold' | 'warm' | 'snack';

export class Recipe extends BaseModel {

    @JsonProperty({ type: 'string' })
    name: string;

    @JsonProperty({ type: 'string?' })
    abstract?: string;

    @JsonProperty({ type: 'string' })
    description: string;

    @JsonProperty({ type: 'string?' })
    img_url?: string;

    @JsonProperty({ type: 'string' })
    type: RecipeType;

    @JsonProperty({ clazz: RecipeStep, type: 'RecipeStep' })
    steps: RecipeStep[]

    @JsonProperty({ clazz: RecipeIngredient, type: 'RecipeIngredient' })
    ingredients: RecipeIngredient[]
}