import * as React from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import Searchbar from '../../components/Searchbar';
import { API } from '../../api/endpoints/discount';
import ClipLoader from 'react-spinners/ClipLoader';
import { Discount } from '../../api/models/discount';
import toastr from 'toastr'

export interface DiscountsProps extends RouteComponentProps {

}

export default withRouter(class Discounts extends React.Component<DiscountsProps, {
    discounts?: Discount[]
    loading: boolean,
    page: number,
    count?: number
}> {


    constructor(props: DiscountsProps) {
        super(props);
        this.state = {
            loading: true,
            page: 1
        }
    }

    private limit: number = 12

    componentDidMount() {
        this.countDiscounts()
        this.getDiscounts()
    }

    getDiscounts() {
        API.getDiscounts(this.limit, this.limit * (this.state.page - 1)).then((discounts) => {
            this.setState({ discounts, loading: false })
        }).catch((err) => {
            this.setState({ loading: false })
        })
    }

    countDiscounts() {
        API.countDiscounts().then((count) => {
            this.setState({count})
        }).catch((err) => {})
    }

    render() {

        return (
            <>
                <Searchbar
                    buttons={[
                        {
                            title: "Rabatte anlegen",
                            onClick: () => { this.props.history.push("/add-discount") }
                        }
                    ]}
                />
                <div className="main">

                    {this.state.loading ?
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                            <ClipLoader />
                        </div>
                        :
                        this.state.discounts ?
                            this.state.discounts.length > 0 ?
                                <div className="row">
                                    {this.state.discounts.map((discount) => {
                                        return this.renderDiscount(discount)
                                    })}
                                </div>
                                :
                                <div>Es gibt noch keine Rabatte</div>
                            :
                            <div>Rabatte können nicht geladen werden</div>
                    }
                </div>
                {!this.state.loading && Boolean(this.state.discounts) && this.state.count && this.state.count > this.limit && <div className="paging">
                    {this.renderPages()}
                </div>}
            </>
        )
    }


    renderDiscount(discount: Discount) {
        return (
            <div className="col-sm-6 col-md-4" key={`discount_${discount.id}`}>
                <div className="newscard">
                    <h6 className="discountHeadline">{discount.name}</h6>
                    <img src={discount.img_url} />
                    <div className="text">
                        <span className="discountText">{discount.description}</span>
                        <p className="accentuation" style={{ marginTop: 10 }}>{discount.url}</p>
                        <p className="accentuation">{`CODE: ${discount.code}`}</p>
                    </div>
                    <div className="edit_delete">
                        <Link to={`/discount-detail?discountId=${discount.id}`}>
                            <i className="fa fa-info-circle"></i>
                        </Link>
                        <Link to={`/edit-discount?discountId=${discount.id}`}>
                            <i className="fa fa-edit"></i>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

    renderPages() {
        if (this.state.count) {
            return (
                <>
                    {this.state.page > 2 && <i className="fa fa-angle-double-left" onClick={() => this.changePage(1)}></i>}
                    {this.state.page > 1 && <i className="fa fa-angle-left" onClick={() => this.changePage(this.state.page - 1)}></i>}
                    {this.state.page - 1 > 0 && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(this.state.page - 1)}>{this.state.page - 1}</span>}
                    <span className="active">{this.state.page}</span>
                    {this.state.page + 1 <= Math.ceil(this.state.count / this.limit) && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(this.state.page + 1)}>{this.state.page + 1}</span>}
                    {this.state.page + 2 < Math.ceil(this.state.count / this.limit) && <span>{'...'}</span>}
                    {this.state.page + 1 < Math.ceil(this.state.count / this.limit) && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(Math.ceil(this.state.count! / this.limit))}>{Math.ceil(this.state.count / this.limit)}</span>}
                    {this.state.page < Math.ceil(this.state.count / this.limit) && <i className="fa fa-angle-right" onClick={() => this.changePage(this.state.page + 1)}></i>}
                    {this.state.page + 1 < Math.ceil(this.state.count / this.limit) && <i className="fa fa-angle-double-right" onClick={() => this.changePage(Math.ceil(this.state.count! / this.limit))}></i>}
                </>
            )
        }
        return
    }

    changePage(page: number) {
        this.setState({page, loading: true}, () => {
            this.getDiscounts()
        })
    }
})