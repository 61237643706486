import * as React from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { USER_PLACEHOLDER } from '../../assets';
import Searchbar from '../../components/Searchbar';
import { Training, TrainingType } from '../../api/models/training';
import { API } from '../../api/endpoints/training';
import ClipLoader from 'react-spinners/ClipLoader';
import { getTrainingsType } from '../../utils/translate';
import { TRAINING_DROPDOWN_ITEMS, TRAINING_TYPE } from '../../utils/constants';

export interface WorkoutsProps extends RouteComponentProps {

}

export default withRouter(class Workouts extends React.Component<WorkoutsProps, {
    loading: boolean
    trainings?: Training[],
    type: TrainingType
    count?: number,
    page: number
}> {

    constructor(props: WorkoutsProps) {
        super(props);
        this.state = {
            loading: true,
            page: 1,
            type: 'business_sedentary_car'
        }
    }

    private limit: number = 10

    componentDidMount() {
        this.countTraining()
        this.getTrainings()
    }

    getTrainings() {
        API.getTrainings(this.state.type, undefined, this.limit, this.limit * (this.state.page - 1)).then((trainings) => {
            this.setState({ trainings, loading: false })
        }).catch((err) => {
            this.setState({ loading: false })
        })
    }

    countTraining() {
        API.countTrainings(this.state.type).then((count) => {
            this.setState({ count })
        }).catch((err) => { })
    }

    render() {

        return (
            <>
                <Searchbar
                    buttons={[
                        {
                            title: "Workout anlegen",
                            onClick: () => { this.props.history.push("/add-workout") }
                        }
                    ]}
                    dropdown={{
                        dropdownItems: TRAINING_DROPDOWN_ITEMS,
                        selected: TRAINING_TYPE.BUSINESS_SEDENTARY_CAR,
                        onChange: (val) => {
                            this.setState({ loading: true, type: val as TrainingType, page: 1 }, () => {
                                this.countTraining()
                                this.getTrainings()
                            })
                        }
                    }}
                />
                <div className="main">
                    {this.state.loading ?
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                            <ClipLoader />
                        </div>
                        :
                        this.state.trainings ?
                            this.state.trainings.length > 0 ?
                                <div className="row">
                                    {this.state.trainings.map((training) => {
                                        return this.renderWorkout(training)
                                    })}
                                </div>
                                :
                                <div>No Trainings yet</div>
                            :
                            <div>Cannot load Trainings</div>
                    }
                </div>
                {!this.state.loading && Boolean(this.state.trainings) && this.state.count && this.state.count > this.limit && <div className="paging">
                    {this.renderPages()}
                </div>}
            </>
        )
    }


    renderWorkout(training: Training) {
        return (
            <div className="col-lg-12" key={`training_${training.id}`}>
                <div className="workoutcard">
                    <div className="row" style={{ alignItems: 'center' }}>
                        <div className="col-md-2 col-3">
                            <img src={training.video_thumbnail} />
                        </div>
                        <div className="col-md-4 col-9">
                            <span className="headline">{training.name}</span>
                            {/*                             <p>Workout freigegeben</p> */}
                        </div>
                        <div className="col-md-4 col-9">
                            <p className="video_text">{getTrainingsType(training.type)}</p>
                        </div>
                        <div className="col-md-2 col-3">
                            <div className="edit_delete">
                                <Link to={`/workout-detail?workoutId=${training.id}`}>
                                    <i className="fa fa-info-circle"></i>
                                </Link>
                                <Link to={`/edit-workout?workoutId=${training.id}`}>
                                    <i className="fa fa-edit"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderPages() {
        if (this.state.count) {
            return (
                <>
                    {this.state.page > 2 && <i className="fa fa-angle-double-left" onClick={() => this.changePage(1)}></i>}
                    {this.state.page > 1 && <i className="fa fa-angle-left" onClick={() => this.changePage(this.state.page - 1)}></i>}
                    {this.state.page - 1 > 0 && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(this.state.page - 1)}>{this.state.page - 1}</span>}
                    <span className="active">{this.state.page}</span>
                    {this.state.page + 1 <= Math.ceil(this.state.count / this.limit) && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(this.state.page + 1)}>{this.state.page + 1}</span>}
                    {this.state.page + 2 < Math.ceil(this.state.count / this.limit) && <span>{'...'}</span>}
                    {this.state.page + 1 < Math.ceil(this.state.count / this.limit) && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(Math.ceil(this.state.count! / this.limit))}>{Math.ceil(this.state.count / this.limit)}</span>}
                    {this.state.page < Math.ceil(this.state.count / this.limit) && <i className="fa fa-angle-right" onClick={() => this.changePage(this.state.page + 1)}></i>}
                    {this.state.page + 1 < Math.ceil(this.state.count / this.limit) && <i className="fa fa-angle-double-right" onClick={() => this.changePage(Math.ceil(this.state.count! / this.limit))}></i>}
                </>
            )
        }
        return
    }

    changePage(page: number) {
        this.setState({page, loading: true}, () => {
            this.getTrainings()
        })
    }
})