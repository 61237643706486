import * as React from 'react'
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { PLAY_BTN, UPLOAD_IMAGE } from '../../assets';
import 'semantic-ui-css/semantic.min.css'
import { API } from '../../api/endpoints/company';
import ClipLoader from 'react-spinners/ClipLoader';
import Button from '../../components/Button';
import toastr from 'toastr'

export const AddEvent: React.FC = () => {
    let query = new URLSearchParams(useLocation().search)
    let eventId = query.get("eventId")
    return (
        <AddEventInner key={eventId} eventId={eventId} />
    )
}

export interface AddEventProps extends RouteComponentProps {
    eventId?: string | null
}

const AddEventInner = withRouter(class extends React.Component<AddEventProps, {
    title: string
    text: string
    img_url?: string
    url: string,
    file?: File | null,
    loading: boolean
    saveLoading: boolean
    deleteLoading: boolean
}> {

    constructor(props: AddEventProps) {
        super(props);
        this.state = {
            loading: true,
            title: "",
            text: "",
            url: "",
            saveLoading: false,
            deleteLoading: false
        }
        this.selectImage = this.selectImage.bind(this)
    }
    private fileInput: HTMLInputElement | null = null

    componentDidMount() {
        this.props.eventId ? this.getCompanyEvent(this.props.eventId) : this.setState({ loading: false })
    }

    getCompanyEvent(eventId: string) {
        API.getCompanyEvent(eventId).then((event) => {
            this.setState({ title: event.name, text: event.description, url: event.url, img_url: event.img_url })
        }).catch((err) => {
            toastr.error("Das Event konnte nicht geladen werden.")
            this.goBack()
        }).finally(() => {
            this.setState({ loading: false })
        })
    }



    render() {
        if (this.state.loading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        return (
            <>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this.selectImage}
                    accept="image/*"
                    ref={ele => (this.fileInput = ele)}
                />
                <div className="create">
                    <div className="row">
                        <div className="col-lg-2 col-6">
                            <Button
                                text={"Speichern"}
                                onClick={() => this.onSave()}
                                loading={this.state.saveLoading}
                                disabled={this.state.saveLoading || this.state.deleteLoading}
                                className={"primary"}
                            />
                        </div>
                        {this.props.eventId &&
                            <div className="col-lg-2 col-6">
                                <Button
                                    text={"Event löschen"}
                                    onClick={() => { this.deleteEvent() }}
                                    loading={this.state.deleteLoading}
                                    disabled={this.state.saveLoading || this.state.deleteLoading}
                                    className={"danger"}
                                />
                            </div>
                        }

                    </div>
                </div>
                <div className="main">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="news_add_card">
                                <div className="row">

                                    <div className="col-sm-6">
                                        <p>Titel</p>
                                        <input
                                            placeholder="Event Titel"
                                            value={this.state.title}
                                            onChange={(event) => {
                                                this.setState({ title: event.target.value })
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <p>URL</p>
                                        <input
                                            placeholder="https://event.de"
                                            value={this.state.url}
                                            onChange={(event) => {
                                                this.setState({ url: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-3 col-6">
                                        <p>Titelbild hinzufügen</p>
                                        <img className="add" src={UPLOAD_IMAGE} onClick={() => {
                                            this.fileInput && this.fileInput.click()
                                        }} />
                                    </div>

                                    <div className="col-md-3 col-6">
                                        <p>Vorschau</p>
                                        {this.state.img_url && <img src={this.state.img_url} />}
                                    </div>

                                    <div className="col-12">
                                        <p>Text</p>
                                        <textarea
                                            rows={20}
                                            placeholder="Event Text"
                                            value={this.state.text}
                                            onChange={(event) => {
                                                this.setState({ text: event.target.value })
                                            }}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }


    selectImage(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        let files: FileList | null = e.target.files
        if (files) {
            const file = files.item(0);
            if (file) {
                const url = URL.createObjectURL(file)
                this.setState({ file, img_url: url })
            }
        }
    }

    onSave() {
        if (this.state.title && this.state.text) {
            this.setState({ saveLoading: true })
            if (this.state.file) {
                /*                 API.uploadImage(this.state.file).then((url) => {
                                    this.addArticle(this.state.title, this.state.text, this.state.type, url)
                                }).catch((err) => {
                                    toastr.warning("Bild konnte nicht hochgeladen werden")
                                    this.addArticle(this.state.title, this.state.text, this.state.type)
                                }) */
                API.uploadCompanyEventImage(this.state.file).then((url) => {
                    this.addEvent(this.state.title, this.state.text, this.state.url, url)
                }).catch((err) => {
                    toastr.warning("Bild konnte nicht hochgeladen werden")
                    this.addEvent(this.state.title, this.state.text, this.state.url)
                })
            } else {
                this.addEvent(this.state.title, this.state.text, this.state.url)
            }
        }
    }

    addEvent(headline: string, text: string, url: string, img_url?: string) {
        if (this.props.eventId) {
            API.updateCompanyEvent(this.props.eventId, headline, text, url, img_url).then((event) => {
                toastr.success("Das Event wurde gespeichert")
                this.goBack()
            }).catch((err) => {
                toastr.error("Das Event konnte nicht gespeichert werden")
            }).finally(() => {
                this.setState({ saveLoading: false })
            })
        } else {
            API.createCompanyEvent(headline, text, url, img_url).then((event) => {
                toastr.success("Das Event wurde gespeichert")
                this.goBack()
            }).catch((err) => {
                toastr.error("Das Event konnte nicht gespeichert werden")
            }).finally(() => {
                this.setState({ saveLoading: false })
            })
        }
    }

    deleteEvent() {
        this.setState({ deleteLoading: true })

        this.props.eventId && API.deleteCompanyEvent(this.props.eventId).then(() => {
            toastr.success("Event wurde gelöscht")
            this.goBack()
        }).catch((err) => {
            toastr.error("Event konnte nicht gelöscht werden")
        }).finally(() => {
            this.setState({ deleteLoading: false })
        })
    }

    goBack() {
        this.props.history.push("/events")
    }

})

export default React.memo(AddEvent)
