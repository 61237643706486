import * as React from 'react'
import { withRouter, RouteComponentProps, useLocation, Link } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { DiscountAPI, NewsAPI } from '../../api/endpoints';
import { Discount } from '../../api/models/discount';
import { News } from '../../api/models/news';
import { getNewsType } from '../../utils/translate';

export const DiscountDetail: React.FC = () => {
    let query = new URLSearchParams(useLocation().search)
    let discountId = query.get("discountId")
    return (
        <DiscountDetailInner key={discountId} discountId={discountId} />
    )
}

export interface DiscountDetailProps extends RouteComponentProps {
    discountId?: string | null
}

const DiscountDetailInner = withRouter(class extends React.Component<DiscountDetailProps, {
    discount?: Discount
    loading: boolean
}> {

    constructor(props: DiscountDetailProps) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
        this.props.discountId ? this.getDiscount(this.props.discountId) : this.setState({ loading: false })
    }

    getDiscount(discountId: string) {
        DiscountAPI.getDiscount(discountId).then((discount) => {
            this.setState({ discount })
        }).catch((err) => {
            /* ToDo */
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        if (this.state.loading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        const { discount } = this.state
        return (
            <div className="main">
                {discount ?
                    <div className="row">
                        <div className="col-xl-8 col-md-10 col-12">
                            <div className="challenge-detail-card">
                                <h3>{discount.name}</h3>
                                {discount.img_url &&
                                    <img className="lg" style={{ marginRight: 0 }} src={discount.img_url} />
                                }
                                <div className="text">
                                    <span className="discountText">{discount.description}</span>
                                    <p className="accentuation" style={{ marginTop: 20 }}>{discount.url}</p>
                                    <p className="accentuation">{`CODE: ${discount.code}`}</p>
                                </div>
                                <div className="edit_delete">
                                    <Link to={`/edit-discount?discountId=${discount.id}`}>
                                        <i className="fa fa-edit"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <div>{"Der Rabatt kann gerade nicht geladen werden."}</div>
                }

            </div>
        )
    }

})

export default React.memo(DiscountDetail)