import * as React from 'react'
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { UPLOAD_IMAGE } from '../../assets';
import 'semantic-ui-css/semantic.min.css'
import { API } from '../../api/endpoints/company';
import ClipLoader from 'react-spinners/ClipLoader';
import Button from '../../components/Button';
import toastr from 'toastr'
import { FIXED_COMPANY_CHALLENGE } from '../../utils/constants';
import { validateEmail } from '../../utils/helper';

export const AddCustomer: React.FC = () => {
    let query = new URLSearchParams(useLocation().search)
    let customerId = query.get("customerId")
    return (
        <AddCustomerInner key={customerId} customerId={customerId} />
    )
}

export interface AddCustomerProps extends RouteComponentProps {
    customerId?: string | null
}

const AddCustomerInner = withRouter(class extends React.Component<AddCustomerProps, {
    name: string,
    email: string,
    password: string,
    contact: string
    phone_number: string
    website: string
    //invite_key: string
    licenses?: string,
    img_url?: string
    file?: File | null,
    loading: boolean
    saveLoading: boolean
    deleteLoading: boolean
    live_eligible: string
}> {

    constructor(props: AddCustomerProps) {
        super(props);
        this.state = {
            loading: true,
            name: "",
            email: "",
            contact: "",
            phone_number: "",
            website: "",
            //invite_key: "",
            password: "",
            saveLoading: false,
            deleteLoading: false,
            live_eligible: "false"
        }
        this.selectImage = this.selectImage.bind(this)
    }
    private fileInput: HTMLInputElement | null = null

    componentDidMount() {
        this.props.customerId ? this.getCompany(this.props.customerId) : this.setState({ loading: false })
    }

    getCompany(customerId: string) {
        API.getCompany(customerId).then((company) => {
            this.setState({ name: company.name, email: company.email, contact: company.contact, phone_number: company.phone_number, website: company.website, img_url: company.img_url, live_eligible: company.live_eligible })
        }).catch((err) => {
            toastr.error("Das Unternehmen konnte nicht geladen werden.")
            this.goBack()
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        if (this.state.loading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        return (
            <>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this.selectImage}
                    accept="image/*"
                    ref={ele => (this.fileInput = ele)}
                />
                <div className="create">
                    <div className="row">
                        <div className="col-lg-2 col-6">
                            <Button
                                text={"Speichern"}
                                onClick={() => this.onSave()}
                                loading={this.state.saveLoading}
                                disabled={this.state.saveLoading || this.state.deleteLoading}
                                className={"primary"}
                            />
                        </div>
                        {this.props.customerId &&
                            <div className="col-lg-2 col-6">
                                <Button
                                    text={"Kunden löschen"}
                                    onClick={() => { this.deleteCompany() }}
                                    loading={this.state.deleteLoading}
                                    disabled={this.state.saveLoading || this.state.deleteLoading}
                                    className={"danger"}
                                />
                            </div>
                        }

                    </div>
                </div>
                <div className="main">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="news_add_card">
                            <span className="info">{"Mit * markierte sind optionale Felder"}</span>
                                <div className="row">

                                    <div className="col-lg-4 col-sm-6">
                                        <p>Name des Unternehmens</p>
                                        <input
                                            placeholder="Mustermann GmbH"
                                            value={this.state.name}
                                            onChange={(event) => {
                                                this.setState({ name: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-lg-4 col-sm-6">
                                        <p>Kontaktperson</p>
                                        <input
                                            placeholder="Max Mustermann"
                                            value={this.state.contact}
                                            onChange={(event) => {
                                                this.setState({ contact: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-lg-4 col-sm-6">
                                        <p>Email</p>
                                        <input
                                            placeholder="info@mustermann.de"
                                            value={this.state.email}
                                            onChange={(event) => {
                                                this.setState({ email: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-lg-4 col-sm-6">
                                        <p>Webseite</p>
                                        <input
                                            placeholder="www.mustermann.de"
                                            value={this.state.website}
                                            onChange={(event) => {
                                                this.setState({ website: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-lg-4 col-sm-6">
                                        <p>Telefonnummer</p>
                                        <input
                                            placeholder="01234/56789"
                                            value={this.state.phone_number}
                                            onChange={(event) => {
                                                this.setState({ phone_number: event.target.value })
                                            }}
                                        />
                                    </div>

{/*                                     {!this.props.customerId && <div className="col-lg-4 col-sm-6">
                                        <p>Einladungscode</p>
                                        <input
                                            placeholder="mustermann_gmbh"
                                            value={this.state.invite_key}
                                            onChange={(event) => {
                                                this.setState({ invite_key: event.target.value })
                                            }}
                                        />
                                    </div>} */}

                                    {!this.props.customerId && <div className="col-lg-4 col-sm-6">
                                        <p>Passwort</p>
                                        <input
                                            type="password"
                                            placeholder=""
                                            value={this.state.password}
                                            onChange={(event) => {
                                                this.setState({ password: event.target.value })
                                            }}
                                        />
                                    </div>}

                                    <div className="col-lg-4 col-sm-6">
                                        <p>{`Lizenzen hinzufügen${this.props.customerId ? ' oder löschen*' : ''}`}</p>
                                        <input
                                            type="number"
                                            placeholder=""
                                            min={this.props.customerId ? undefined : 0}
                                            value={this.state.licenses}
                                            onChange={(event) => {
                                                this.setState({ licenses: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className={this.props.customerId ? "col-12" : "col-lg-8 col-sm-6" }/>

                                    <div className="col-lg-4 col-6">
                                        <p>Logo hinzufügen*</p>
                                        <img className="add" src={UPLOAD_IMAGE} onClick={() => {
                                            this.fileInput && this.fileInput.click()
                                        }} />
                                    </div>

                                    <div className="col-lg-4 col-6">
                                        <p>Vorschau</p>
                                        {this.state.img_url && <img src={this.state.img_url} />}
                                    </div>

                                    <div className="col-lg-4 col-sm-6">
                                        <p>LIVE Welt aktiviert</p>
                                        <input type="checkbox" checked={this.state.live_eligible == 'true'}
                                            onChange={(event) => {
                                                this.setState({live_eligible: this.state.live_eligible == 'false' ? 'true' : 'false'})
                                            }}></input>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }


    selectImage(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        let files: FileList | null = e.target.files
        if (files) {
            const file = files.item(0);
            if (file) {
                const url = URL.createObjectURL(file)
                this.setState({ file, img_url: url })
            }
        }
    }

    onSave() {
        if (this.isValid()) {
            this.setState({ saveLoading: true })
            if (this.state.file) {
                API.uploadCompanyImage(this.state.file).then((url) => {
                    this.addCompany(url)
                }).catch((err) => {
                    toastr.warning("Bild konnte nicht hochgeladen werden")
                    this.addCompany()
                })
            } else {
                this.addCompany()
            }
        }
    }

    isValid() {
        let valid = true
        if (!this.state.name) {
            toastr.warning("Bitte gebe einen Namen an")
            valid = false
        }
        if (!this.state.contact) {
            toastr.warning("Bitte gebe eine Kontaktperson an")
            valid = false
        }
        if (!this.state.email) {
            toastr.warning("Bitte gebe eine Email Adresse an")
            valid = false
        }
        if(!validateEmail(this.state.email)) {
            toastr.warning("Bitte gebe eine gültige Email Adresse an")
            valid = false
        }
        if (!this.state.website) {
            toastr.warning("Bitte gebe eine Webseite an")
            valid = false
        }
        if (!this.state.phone_number) {
            toastr.warning("Bitte gebe eine Telefonnummer an")
            valid = false
        }
        if (!this.props.customerId && !this.state.licenses) {
            toastr.warning("Bitte gebe die Anzahl an Lizenzen an")
            valid = false
        }
        if (this.state.licenses && isNaN(Number(this.state.licenses))) {
            toastr.warning("Bitte gebe eine gültige Anzahl an Lizenzen an")
            valid = false
        }
        if (!this.props.customerId && !this.state.password) {
            toastr.warning("Bitte gebe ein Passwort an")
            valid = false
        }
        return valid
    }

    addCompany(img_url?: string) {
        const { email, password, contact, name, phone_number, website, licenses, live_eligible} = this.state
        if (this.props.customerId) {
            API.updateCompany(this.props.customerId, name, contact, email, phone_number, website, img_url, Number(licenses), live_eligible).then((company) => {
                toastr.success("Unternehmen wurde gespeichert")
                this.goBack()
            }).catch((err) => {
                toastr.error("Unternehmen konnte nicht gespeichert werden")
            }).finally(() => {
                this.setState({ saveLoading: false })
            })
        } else {
            API.addCompany({
                registration_data: {
                    email,
                    password
                },
                registration_fullname: contact,
                company: {
                    name,
                    phone_number,
                    website,
                    //invite_key,
                    img_url
                },
                fixedChallenges: [],
                invite_keys: Number(licenses)
            }).then((company) => {
                toastr.success("Unternehmen wurde gespeichert")
                this.goBack()
            }).catch((err) => {
                toastr.error("Unternehmen konnte nicht gespeichert werden")
            }).finally(() => {
                this.setState({ saveLoading: false })
            })
        }

    }

    deleteCompany() {
        this.setState({ deleteLoading: true })
        this.props.customerId && API.deleteCompany(this.props.customerId).then(() => {
            toastr.success("Unternehmen wurde gelöscht")
            this.goBack()
        }).catch((err) => {
            toastr.error("Unternehmen konnte nicht gelöscht werden")
        }).finally(() => {
            this.setState({ deleteLoading: false })
        })
    }

    goBack() {
        this.props.history.push("/customers")
    }

})

export default React.memo(AddCustomer)