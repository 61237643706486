import * as React from 'react'
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { PLAY_BTN, UPLOAD_IMAGE } from '../../assets';
import { NEWS_TYPE } from '../../utils/constants';
import { Dropdown } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import { NewsType } from '../../api/models/news';
import { News as INews } from '../../api/models/news'
import ClipLoader from 'react-spinners/ClipLoader';
import Button from '../../components/Button';
import toastr from 'toastr'
import { DiscountAPI } from '../../api/endpoints';

export const AddDiscount: React.FC = () => {
    let query = new URLSearchParams(useLocation().search)
    let discountId = query.get("discountId")
    return (
        <AddDiscountInner key={discountId} discountId={discountId} />
    )
}

export interface AddDiscountProps extends RouteComponentProps {
    discountId?: string | null
}

const AddDiscountInner = withRouter(class extends React.Component<AddDiscountProps, {
    name: string
    description: string
    url: string,
    code: string
    img_url?: string
    file?: File | null,
    loading: boolean
    saveLoading: boolean
    deleteLoading: boolean
}> {

    constructor(props: AddDiscountProps) {
        super(props);
        this.state = {
            loading: true,
            name: "",
            description: "",
            url: "",
            code: "",
            saveLoading: false,
            deleteLoading: false
        }
        this.selectImage = this.selectImage.bind(this)
    }
    private fileInput: HTMLInputElement | null = null

    componentDidMount() {
        this.props.discountId ? this.getDiscount(this.props.discountId) : this.setState({ loading: false })
    }

    getDiscount(discountId: string) {
        DiscountAPI.getDiscount(discountId).then((discount) => {
            this.setState({ name: discount.name, description: discount.description, url: discount.url, code: discount.code, img_url: discount.img_url })
        }).catch((err) => {
            toastr.error("Der Rabatt konnte nicht geladen werden.")
            this.goBack()
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        if (this.state.loading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        return (
            <>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this.selectImage}
                    accept="image/*"
                    ref={ele => (this.fileInput = ele)}
                />
                <div className="create">
                    <div className="row">
                        <div className="col-lg-2 col-6">
                            <Button
                                text={"Speichern"}
                                onClick={() => this.onSave()}
                                loading={this.state.saveLoading}
                                disabled={this.state.saveLoading || this.state.deleteLoading}
                                className={"primary"}
                            />
                        </div>
                        {this.props.discountId &&
                            <div className="col-lg-2 col-6">
                                <Button
                                    text={"Discount löschen"}
                                    onClick={() => {this.deleteDiscount()}}
                                    loading={this.state.deleteLoading}
                                    disabled={this.state.saveLoading || this.state.deleteLoading}
                                    className={"danger"}
                                />
                            </div>
                        }

                    </div>
                </div>
                <div className="main">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="news_add_card">
                                <div className="row">

                                    <div className="col-md-5 col-12">
                                        <p>Name</p>
                                        <input
                                            placeholder="Rabatt Name"
                                            value={this.state.name}
                                            onChange={(event) => {
                                                this.setState({ name: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-5 col-8">
                                        <p>URL (Format beachten: http://... oder https://...)</p>
                                        <input
                                            placeholder="https://rabatt.de"
                                            value={this.state.url}
                                            onChange={(event) => {
                                                this.setState({ url: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-2 col-4">
                                        <p>Code</p>
                                        <input
                                            placeholder="FIT22"
                                            value={this.state.code}
                                            onChange={(event) => {
                                                this.setState({ code: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-6 col-12">
                                        <p>Beschreibung</p>
                                        <textarea
                                            rows={5}
                                            placeholder="Beschreibung..."
                                            value={this.state.description}
                                            onChange={(event) => {
                                                this.setState({ description: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-3 col-6">
                                        <p>Titelbild hinzufügen</p>
                                        <img className="add" src={UPLOAD_IMAGE} onClick={() => {
                                            this.fileInput && this.fileInput.click()
                                        }} />
                                    </div>

                                    <div className="col-md-3 col-6">
                                        <p>Vorschau</p>
                                        {this.state.img_url && <img src={this.state.img_url} />}
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }


    selectImage(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        let files: FileList | null = e.target.files
        if (files) {
            const file = files.item(0);
            if (file) {
                const url = URL.createObjectURL(file)
                this.setState({ file, img_url: url })
            }
        }
    }

    onSave() {
        if (this.isValid()) {
            this.setState({ saveLoading: true })
            if (this.state.file) {
                DiscountAPI.uploadDiscountImage(this.state.file).then((url) => {
                    this.addDiscount(url)
                }).catch((err) => {
                    toastr.warning("Bild konnte nicht hochgeladen werden")
                    this.addDiscount()
                })
            } else {
                this.addDiscount()
            }
        }
    }

    isValid() {
        let valid = true
        if(!this.state.name) {
            toastr.warning("Bitte gebe einen Namen an")
            valid = false
        }
        if(!this.state.description) {
            toastr.warning("Bitte gebe eine Beschreibung an")
            valid = false
        }
        if(!this.state.url) {
            toastr.warning("Bitte gebe eine Url an")
            valid = false
        }
        if(!this.state.code) {
            toastr.warning("Bitte gebe einen Code an")
            valid = false
        }
        return valid
    }

    addDiscount(img_url?: string) {
        if (this.props.discountId) {
            DiscountAPI.updateDiscount(this.props.discountId, this.state.name, this.state.description, this.state.url, this.state.code, img_url).then((discount) => {
                toastr.success("Rabatt wurde gespeichert")
                this.goBack()
            }).catch((err) => {
                toastr.error("Rabatt konnte nicht gespeichert werden")
            }).finally(() => {
                this.setState({ saveLoading: false })
            })
        } else {
            DiscountAPI.addDiscount(this.state.name, this.state.description, this.state.url, this.state.code, img_url).then((discount) => {
                toastr.success("Rabatt wurde gespeichert")
                this.goBack()
            }).catch((err) => {
                toastr.error("Rabatt konnte nicht gespeichert werden")
            }).finally(() => {
                this.setState({ saveLoading: false })
            })
        }
    }

    deleteDiscount() {
        this.setState({deleteLoading: true})
        this.props.discountId && DiscountAPI.deleteDiscount(this.props.discountId).then(() => {
            toastr.success("Rabatt wurde gelöscht")
            this.goBack()
        }).catch((err) => {
            toastr.error("Rabatt konnte nicht gelöscht werden")
        }).finally(() => {
            this.setState({deleteLoading: false})
        })
    }

    goBack() {
        this.props.history.push("/discounts")
    }

})

export default React.memo(AddDiscount)