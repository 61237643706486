import { News } from "../models/news";
import { Recipe, RecipeType } from "../models/recipe";
import { RecipeIngredient } from "../models/recipeIngredient";
import { RecipeStep } from "../models/recipeStep";
import { APIVersion, AuthenticatedFileRequest, AuthenticatedRequest, HTTPMethod } from "../requests/request"

export class API {

    /**
     * Get the recipes
     */
    public static getRecipes(type?: RecipeType, limit?: number, offset?: number): Promise<Recipe[]> {
        let apiReqest = new AuthenticatedRequest<Recipe>('/recipes', HTTPMethod.GET, APIVersion.v1_0, {
            type,
            limit,
            offset
        });
        return apiReqest.send(Recipe).then((response) => {
            return <Recipe[]>response.data
        })
    }

    /**
     * Returns a specific Recipe
     * 
     * @param id 
     */
    public static getRecipe(id: string): Promise<Recipe> {
        let apiReqest = new AuthenticatedRequest<Recipe>(`/recipes/${id}`, HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Upload a Picture
     * 
     * @param image Image file
     */
    public static uploadRecipeImage(image: any): Promise<string> {
        let apiReqest = new AuthenticatedFileRequest<string>('/recipes/picture', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            image
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Add a new Recipe
     * 
     * @param name 
     * @param description 
     * @param type 
     * @param abstract 
     * @param img_url 
     */
    public static addRecipe(name: string, description: string, type: RecipeType, abstract?: string, img_url?: string): Promise<Recipe> {
        let apiReqest = new AuthenticatedRequest<Recipe>('/recipes', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            name,
            description,
            type,
            abstract,
            img_url
        });
        return apiReqest.send(Recipe).then((response) => {
            return <Recipe>response.data
        })
    }

    public static updateRecipe(id: string, name?: string, description?: string, type?: RecipeType, abstract?: string, img_url?: string): Promise<Recipe> {
        let apiReqest = new AuthenticatedRequest<Recipe>(`/recipes/${id}`, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            name,
            description,
            type,
            abstract,
            img_url
        });
        return apiReqest.send(Recipe).then((response) => {
            return <Recipe>response.data
        })
    }

    /**
     * Deletes a Recipe
     * 
     * @param id 
     */
    public static deleteRecipe(id: string): Promise<{}> {
        let apiReqest = new AuthenticatedRequest<{}>(`/recipes/${id}`, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return <{}>response.data
        })
    }

    /**
     * Add steps to a recipe
     * 
     * @param id 
     * @param steps 
     */
    public static addRecipeSteps(id: string, steps: RecipeStep[]): Promise<string> {
        let apiReqest = new AuthenticatedRequest<string>(`/recipes/${id}/steps`, HTTPMethod.POST, APIVersion.v1_0, undefined, steps);
        return apiReqest.send().then((response) => {
            return <string>response.data
        })
    }

    /**
     * Add ingredients to a recipe
     * 
     * @param id 
     * @param ingredients 
     */
    public static addRecipeIngredients(id: string, ingredients: RecipeIngredient[]): Promise<string> {
        let apiReqest = new AuthenticatedRequest<string>(`/recipes/${id}/ingredients`, HTTPMethod.POST, APIVersion.v1_0, undefined, ingredients);
        return apiReqest.send().then((response) => {
            return <string>response.data
        })
    }

    /**
     * Add a Recipe to favorites
     * 
     * @param recipeId 
     */
    public static addFavorite(recipeId: string): Promise<any> {
        let apiReqest = new AuthenticatedRequest<any>(`/recipes/${recipeId}/favorite/add`, HTTPMethod.POST, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Remove a Recipe from favorites
     * 
     * @param recipeId 
     */
    public static removeFavorite(recipeId: string): Promise<any> {
        let apiReqest = new AuthenticatedRequest<any>(`/recipes/${recipeId}/favorite/remove`, HTTPMethod.POST, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

        /**
     * Get the Recipe Favorites
     */
    public static getFavorites(): Promise<Recipe[]> {
        let apiReqest = new AuthenticatedRequest<Recipe[]>('/recipes/favorites', HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Count Recipes
     * 
     * @param type RecipeType
     */
    public static countRecipes(type?: RecipeType): Promise<number> {
        let apiReqest = new AuthenticatedRequest<number>('/recipes/count', HTTPMethod.GET, APIVersion.v1_0, {
            type
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }
}