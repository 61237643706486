import * as React from 'react'
import { withRouter, RouteComponentProps, useLocation, Link } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { CompanyAPI, TrainingAPI } from '../../api/endpoints';
import { Company } from '../../api/models/company';
import { Training } from '../../api/models/training';
import { CONTACT_ICON, MAIL_ICON, PHONE_ICON, WEBSITE_ICON } from '../../assets';
import { getTrainingsType } from '../../utils/translate';

export const MeditationDetail: React.FC = () => {
    let query = new URLSearchParams(useLocation().search)
    let meditationId = query.get("meditationId")
    return (
        <MeditationDetailInner key={meditationId} meditationId={meditationId} />
    )
}

export interface MeditationDetailProps extends RouteComponentProps {
    meditationId?: string | null
}

const MeditationDetailInner = withRouter(class extends React.Component<MeditationDetailProps, {
    training?: Training
    loading: boolean
}> {

    constructor(props: MeditationDetailProps) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
        this.props.meditationId ? this.getTraining(this.props.meditationId) : this.setState({ loading: false })
    }

    getTraining(meditationId: string) {
        TrainingAPI.getTraining(meditationId).then((training) => {
            this.setState({ training })
        }).catch((err) => {
            /* ToDo */
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        if (this.state.loading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        const { training } = this.state
        return (
            <div className="main">
                {training ?
                    <div className="row">
                        <div className="col-xl-8 col-md-10 col-sm-12 col-10">
                            <div className="workout-detail-card">

                                <h3>{training.name}</h3>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="info">{getTrainingsType(training.type)}</div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="info">{`${training.points} Punkte`}</div>
                                    </div>
                                    {training.video_thumbnail && <div className="col-sm-6">
                                        <img src={training.video_thumbnail} />
                                    </div>}
                                    <div className="col-sm-6">
                                        <video src={training.video_url} controls poster={training.video_thumbnail} />
                                    </div>
                                </div>
                                <div className="edit_delete">
                                    <Link to={`/edit-meditation?meditationId=${training.id}`}>
                                        <i className="fa fa-edit"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <div>{"Die Meditation kann gerade nicht geladen werden."}</div>
                }

            </div>
        )
    }

})

export default React.memo(MeditationDetail)