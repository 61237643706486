import * as React from 'react'
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { UPLOAD_IMAGE } from '../../assets';
import { TRAINING_DROPDOWN_ITEMS, TRAINING_TYPE } from '../../utils/constants';
import { Dropdown } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import { API } from '../../api/endpoints/training';
import ClipLoader from 'react-spinners/ClipLoader';
import Button from '../../components/Button';
import toastr from 'toastr'
import { TrainingType } from '../../api/models/training';

export const AddWorkout: React.FC = () => {
    let query = new URLSearchParams(useLocation().search)
    let workoutId = query.get("workoutId")
    return (
        <AddWorkoutInner key={workoutId} workoutId={workoutId} />
    )
}

export interface AddWorkoutProps extends RouteComponentProps {
    workoutId?: string | null
}

const AddWorkoutInner = withRouter(class extends React.Component<AddWorkoutProps, {
    title: string
    type: TrainingType,
    points: string
    video_file?: File | null,
    img_file?: File | null
    video_url?: string,
    img_url?: string
    loading: boolean
    saveLoading: boolean
    deleteLoading: boolean
}> {

    constructor(props: AddWorkoutProps) {
        super(props);
        this.state = {
            type: TRAINING_TYPE.BUSINESS_SEDENTARY_CAR as TrainingType,
            loading: true,
            title: "",
            saveLoading: false,
            deleteLoading: false,
            points: ""
        }
        this.selectImage = this.selectImage.bind(this)
        this.selectVideo = this.selectVideo.bind(this)
    }
    private videoInput: HTMLInputElement | null = null
    private imgInput: HTMLInputElement | null = null
    private video: HTMLVideoElement | null = null

    componentDidMount() {
        this.props.workoutId ? this.getTraining(this.props.workoutId) : this.setState({ loading: false })
    }

    getTraining(trainingId: string) {
        API.getTraining(trainingId).then((training) => {
            this.setState({ title: training.name, type: training.type, img_url: training.video_thumbnail, video_url: training.video_url, points: training.points.toString() })
        }).catch((err) => {
            toastr.error("Das Workout konnte nicht geladen werden.")
            this.goBack()
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        if (this.state.loading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        return (
            <>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this.selectVideo}
                    accept="video/*"
                    ref={ele => (this.videoInput = ele)}
                />
                <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this.selectImage}
                    accept="image/*"
                    ref={ele => (this.imgInput = ele)}
                />
                <div className="create">
                    <div className="row">
                        <div className="col-lg-2 col-6">
                            <Button
                                text={"Speichern"}
                                onClick={() => this.onSave()}
                                loading={this.state.saveLoading}
                                disabled={this.state.saveLoading || this.state.deleteLoading}
                                className={"primary"}
                            />
                        </div>
                        {this.props.workoutId &&
                            <div className="col-lg-2 col-6">
                                <Button
                                    text={"Workout löschen"}
                                    onClick={() => { this.deleteWorkout() }}
                                    loading={this.state.deleteLoading}
                                    disabled={this.state.saveLoading || this.state.deleteLoading}
                                    className={"danger"}
                                />
                            </div>
                        }

                    </div>
                </div>
                <div className="main">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="workout_add_card">
                                <div className="row">

                                    <div className="col-md-6">
                                        <p>Titel</p>
                                        <input
                                            placeholder="Workout Titel"
                                            value={this.state.title}
                                            onChange={(event) => {
                                                this.setState({ title: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-xl-5 col-md-4 col-9">
                                        <p>Kategorie</p>
                                        <Dropdown
                                            value={this.state.type}
                                            fluid
                                            selection
                                            options={TRAINING_DROPDOWN_ITEMS}
                                            onChange={(_, { value }) => {
                                                this.setState({ type: value as TrainingType })
                                            }}
                                        />
                                    </div>

                                    <div className="col-xl-1 col-md-2 col-3">
                                        <p>Punkte</p>
                                        <input
                                            placeholder="50"
                                            value={this.state.points}
                                            onChange={(event) => {
/*                                                 if (!isNaN(Number(event.target.value))) {
                                                    this.setState({ points: Number(event.target.value) })
                                                } */
                                                this.setState({points: event.target.value})
                                            }}
                                        />
                                    </div>

                                    <div className="col-lg-3 col-6">
                                        <p>Video hinzufügen</p>
                                        <img className="add" src={UPLOAD_IMAGE} onClick={() => {
                                            this.videoInput && this.videoInput.click()
                                        }} />
                                    </div>

                                    <div className="col-lg-3 col-6">
                                        <p>Vorschau</p>
                                        <video ref={(ref) => this.video = ref} controls poster={this.state.img_url} src={this.state.video_url} />
                                    </div>

                                    <div className="col-lg-3 col-6">
                                        <p>Titelbild hinzufügen</p>
                                        <img className="add" src={UPLOAD_IMAGE} onClick={() => {
                                            this.imgInput && this.imgInput.click()
                                        }} />
                                    </div>

                                    <div className="col-lg-3 col-6">
                                        <p>Vorschau</p>
                                        {this.state.img_url && <img src={this.state.img_url} />}
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }


    selectImage(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        let files: FileList | null = e.target.files
        if (files) {
            const file = files.item(0);
            if (file) {
                const url = URL.createObjectURL(file)
                this.setState({ img_file: file, img_url: url })
            }
        }
    }

    selectVideo(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        let files: FileList | null = e.target.files
        if (files) {
            const file = files.item(0);
            if (file) {
                const url = URL.createObjectURL(file)
                this.video && (this.video.src = url)
                this.setState({ video_file: file, video_url: url })
            }
        }
    }

    onSave() {
        if (this.isValid()) {
            this.setState({ saveLoading: true })
            this.uploadVideo()
        }
    }

    uploadVideo() {
        if (this.state.video_file) {
            API.uploadTrainingVideo(this.state.video_file).then((res) => {
                this.uploadImage(res.mp4)
            }).catch((err) => {
                toastr.error("Video konnte nicht hochgeladen werden")
                this.setState({ saveLoading: false })
            })
        } else {
            this.uploadImage()
        }
    }

    uploadImage(video_url?: string) {
        if (this.state.img_file) {
            API.uploadTrainingImage(this.state.img_file).then((img_url) => {
                this.addTraining(video_url, img_url)
            }).catch((err) => {
                toastr.warning("Bild konnte nicht hochgeladen werden")
                this.addTraining(video_url)
            })
        } else {
            this.addTraining(video_url)
        }
    }

    isValid() {
        let valid = true
        if(!this.state.title) {
            toastr.warning("Bitte gebe einen Titel an")
            valid = false
        }
        if(!(this.state.video_file || this.state.video_url)) {
            toastr.warning("Bitte wähle ein Video aus")
            valid = false
        }
        if(!this.state.points) {
            toastr.warning("Bitte gebe eine Punktzahl an")
            valid = false
        }
        if(this.state.points && isNaN(Number(this.state.points))) {
            toastr.warning("Bitte gebe eine gültige Punktzahl an")
            valid = false
        }
        return valid
    }

    addTraining(video_url?: string, video_thumbnail?: string) {
        if (this.props.workoutId) {
            API.updateTraining(this.props.workoutId, this.state.title, video_url, this.state.type, Number(this.state.points), video_thumbnail).then((training) => {
                toastr.success("Training wurde gespeichert")
                this.goBack()
            }).catch((err) => {
                toastr.error("Training konnte nicht gespeichert werden")
            }).finally(() => {
                this.setState({ saveLoading: false })
            })
        } else {
            API.addTraining(this.state.title, video_url!, this.state.type, Number(this.state.points), video_thumbnail).then((training) => {
                toastr.success("Training wurde gespeichert")
                this.goBack()
            }).catch((err) => {
                toastr.error("Training konnte nicht gespeichert werden")
            }).finally(() => {
                this.setState({ saveLoading: false })
            })
        }
    }

    deleteWorkout() {
        this.setState({ deleteLoading: true })
        this.props.workoutId && API.deleteTraining(this.props.workoutId).then(() => {
            toastr.success("Training wurde gelöscht")
            this.goBack()
        }).catch((err) => {
            toastr.error("Training konnte nicht gelöscht werden")
        }).finally(() => {
            this.setState({ deleteLoading: false })
        })
    }

    goBack() {
        this.props.history.push("/workout")
    }

})

export default React.memo(AddWorkout)

