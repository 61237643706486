import { JsonProperty } from "../requests/jsonparser";
import { BaseModel } from "./base";
export class Partner extends BaseModel {

    @JsonProperty({ type: 'string' })
    company_name: string;

    @JsonProperty({ type: 'string' })
    url: string;

    @JsonProperty({ type: 'string' })
    partner: string;

    @JsonProperty({ type: 'string' })
    mail: string;

    @JsonProperty({ type: 'string' })
    phone: string;

}