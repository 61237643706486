const local = {
    API: 'http://192.168.2.102:8082',
    ENV: 'local'
};
const dev = {
    API: 'https://dev-api.fitjo-app.link',
    ENV: 'development'
};
const prod = {
    API: 'https://api.fitjo-app.link',
    ENV: 'production'
};
const config = process.env.REACT_APP_ENV === 'local' ? prod :
    process.env.REACT_APP_ENV === 'prod'
        ? prod
        : dev;
export let Environment = config