import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UserContextType } from '../../context/user';
import Button from '../../components/Button';
import { UPLOAD_IMAGE } from '../../assets';
import ClipLoader from 'react-spinners/ClipLoader';
import { validateEmail } from '../../utils/helper';
import { UserAPI } from '../../api/endpoints';
import { UserSession } from '../../utils/session';
import toastr from 'toastr'

export interface ChangeProfileProps extends RouteComponentProps {

}

export default withRouter(class ChangeProfile extends React.Component<ChangeProfileProps, {
    file?: File | null,
    img_url?: string,
    loading: boolean
    saveLoading: boolean,
    name: string,
    email: string,
}> {

    static contextType = UserContextType
    context!: React.ContextType<typeof UserContextType>


    constructor(props: ChangeProfileProps) {
        super(props);
        this.state = {
            saveLoading: false,
            name: "",
            email: "",
            loading: true
        }
        this.selectImage = this.selectImage.bind(this)
    }

    private fileInput: HTMLInputElement | null = null

    componentDidMount() {
        if (this.context) {
            this.setState({
                name: this.context.full_name,
                email: this.context.email,
                img_url: this.context.profile_image_url
            }, () => {this.setState({loading: false})})
        }

    }

    render() {
        if (this.state.loading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        return (
            <>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this.selectImage}
                    accept="image/*"
                    ref={ele => (this.fileInput = ele)}
                />
                <div className="create">
                    <div className="row">
                        <div className="col-lg-2 col-6">
                            <Button
                                text={"Speichern"}
                                onClick={() => this.onSave()}
                                loading={this.state.saveLoading}
                                disabled={this.state.saveLoading}
                                className={"primary"}
                            />
                        </div>
                    </div>
                </div>
                <div className="main">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="news_add_card">
                                <div className="row">

                                    <div className="col-sm-6">
                                        <p>Name</p>
                                        <input
                                            placeholder="Name"
                                            value={this.state.name}
                                            onChange={(event) => {
                                                this.setState({ name: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-sm-6">
                                        <p>Email</p>
                                        <input
                                            placeholder="Email"
                                            value={this.state.email}
                                            onChange={(event) => {
                                                this.setState({ email: event.target.value })
                                            }}
                                        />
                                    </div>


                                    <div className="col-md-3 col-6">
                                        <p>Profilbild hinzufügen</p>
                                        <img className="add" src={UPLOAD_IMAGE} onClick={() => {
                                            this.fileInput && this.fileInput.click()
                                        }} />
                                    </div>

                                    <div className="col-md-3 col-6">
                                        <p>Vorschau</p>
                                        {this.state.img_url && <img src={this.state.img_url} />}
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }

    selectImage(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        let files: FileList | null = e.target.files
        if (files) {
            const file = files.item(0);
            if (file) {
                const url = URL.createObjectURL(file)
                this.setState({ file, img_url: url })
            }
        }
    }

    onSave() {
        if(this.isValid()) {
            this.setState({ saveLoading: true })
            if (this.state.file) {
                UserAPI.uploadProfileImage(this.state.file).then((user) => {
                    toastr.success("Bild wurde hochgeladen")
                    UserSession.getUserUpdatedHandler()(user)
                }).catch((err) => {
                    toastr.success("Bild konnte nicht hochgeladen werden")
                }).finally(() => {
                    this.updateUser()
                })
            } else {
                this.updateUser()
            }
        }
    }

    updateUser() {
        UserAPI.updateProfile(this.state.email, this.state.name).then((user) => {
            toastr.success("Profil wurde gespeichert")
            UserSession.getUserUpdatedHandler()(user)
            this.props.history.push("/profile")
        }).catch((err) => {
            toastr.error("Profil konnte nicht gespeichert werden")
        }).finally(() => {
            this.getUser()
            this.setState({ saveLoading: false })
        })
    }

    isValid() {
        let valid = true
        if(!validateEmail(this.state.email)) {
            toastr.warning("Bitte gebe eine gültige Email Adresse an")
            valid = false
        }
        return valid
    }

    getUser() {
        UserAPI.getUser().then((user) => {
            UserSession.getUserUpdatedHandler()(user)
        }).catch((err) => {})
    }
})