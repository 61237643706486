import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router';
import { LOGO } from '../assets';
import {validateEmail} from "../utils/helper";
import {getDeviceData} from "../utils/authentication_utils";
import {API} from "../api/endpoints/login";
import toastr from "toastr";

interface ResetPWProps extends RouteComponentProps {

}
export default withRouter(class ResetPW extends React.Component<ResetPWProps, {}> {

    private emailInput: HTMLInputElement | null = null

    render() {

        return (
            <div className="login_container">
                <div className="logo_login">
                    <img src={LOGO} />
                </div>

                <div className="content_login">
                    <div className="image" />
                    <div className="textbox">
                        <div className="text">
                            <h1>Passwort zurücksetzen</h1>
                            <p>Bitte füllen Sie das untere Feld mit ihrer Email Adresse aus. Daraufhin wird Ihnen eine Email mit weiteren Schritten zugeschickt. </p>
                            <input ref={(ref) => this.emailInput = ref} placeholder="Benutzername / Email Adresse" type="email" />
                            <button onClick={() => this.resetPw()}>Absenden</button>
                        </div>
                    </div>
                </div>

                <div className="copyright_login">
                    Copyright  © 2021 by FITJO
                </div>
            </div>
        )
    }

    resetPw() {
        let email = this.emailInput?.value
        if (email && validateEmail(email)) {
            getDeviceData().then(() => {
                API.forgotPassword(email!).then((device) => {
                    toastr.info("Email mit weiteren Anweisungen wurde verschickt.");
                    this.props.history.push("/dashboard");
                }).catch((err) => {
                    toastr.error("Der Login war nicht erfolgreich, bitte überprüfe deine email-Adresse und dein Passwort")
                })
            }).catch((err) => {
                toastr.error("Entschuldige, das klappt leider gerade nicht.")
            })
        } else {
            toastr.warning("Ungültige Email-Adresse")
        }
    }
})

