import { TrainingType } from './../api/models/training';
import { NewsType } from "../api/models/news"
import { RecipeType } from '../api/models/recipe';
import { PointsType } from '../api/models/challenge';

export function getNewsType(type: NewsType) {
    switch(type) {
        case 'general':
            return 'Allgemein'
        case 'office':
            return 'Office'
        case 'nutrition':
            return 'Ernährung'
        case 'training':
            return 'Training & Bewegung'
    }
}

export function getTrainingsType(type: TrainingType) {
    switch(type) {
        case 'business_sedentary_car':
            return 'Business sitzend im Auto'
        case 'business_sedentary_desk':
            return 'Business sitzend am Schreibtisch'
        case 'business_standing':
            return 'Business stehend'
        case 'home_alb':
            return 'Bauch, Beine, Po'
        case 'home_backfit':
            return 'Rückenfit'
        case 'home_hiit':
            return 'HIIT'
        case 'home_mobility':
            return 'Mobility'
        case 'home_whole_body':
            return 'Ganzkörpertraining'
        case 'meditation':
            return 'Meditation'
        case 'home_upper_body':
            return 'Oberkörper'
    }
}

export function getRecipeType(type: RecipeType) {
    switch(type) {
        case 'breakfast':
            return 'Frühstück'
        case 'cold':
            return 'Kalte Speisen'
        case 'warm':
            return 'Warme Speisen'
        case 'snack':
            return 'Snacks'
    }
}
