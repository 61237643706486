import * as React from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import Searchbar from '../../components/Searchbar';
import ClipLoader from 'react-spinners/ClipLoader';
import { User } from '../../api/models/user';
import { UserAPI } from '../../api/endpoints';
import { USER_PLACEHOLDER } from '../../assets';
import toastr from 'toastr'

export interface ContributorsProps extends RouteComponentProps {

}

export default withRouter(class Contributors extends React.Component<ContributorsProps, {
    loading: boolean
    user?: User[],
    page: number,
    count?: number
}> {

    constructor(props: ContributorsProps) {
        super(props);
        this.state = {
            loading: true,
            page: 1
        }
    }

    private limit: number = 12

    componentDidMount() {
        this.getUser()
    }

    getUser() {
        UserAPI.getCompanyUser().then((user) => {
            this.setState({ user, loading: false })
        }).catch((err) => {
            this.setState({ loading: false })
        })
    }

    countUser() {
        UserAPI.countCompanyUser().then((count) => {
            this.setState({count})
        }).catch((err) => {})
    }

    render() {

        return (
            <div>
                <Searchbar />
                <div className="main">
                    {this.state.loading ?
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                            <ClipLoader />
                        </div>
                        :
                        this.state.user ?
                            this.state.user.length > 0 ?
                                <div className="row">
                                    {this.state.user.map((user) => {
                                        return this.renderContributor(user)
                                    })}
                                </div>
                                :
                                <div>No User yet</div>
                            :
                            <div>Cannot load User</div>
                    }
                </div>
                {!this.state.loading && Boolean(this.state.user) && this.state.count && this.state.count > this.limit && <div className="paging">
                    {this.renderPages()}
                </div>}
            </div>
        )
    }

    renderContributor(user: User) {
        return (
            <div className="col-xl-3 col-lg-4 col-md-6" key={`user_${user.id}`}>
                <div className="usercard">
                    <img src={user.profile_image_url || USER_PLACEHOLDER} />
                    <div className="userlines">
                        <p>{user.full_name}</p>
                        <p>{user.email}</p>
                    </div>
                    {user.role && (user.role === 'user') && <div className="edit_delete" style={{cursor: "pointer"}} onClick={() => {this.deleteUser(user.id!)}}>
                        <i className="fa fa-trash"></i>
                    </div>}
                </div>
            </div>
        )
    }

    deleteUser(id: string) {
        UserAPI.deleteUser(id).then(() => {
            toastr.success("Mitarbeiter wurde gelöscht")
            this.getUser()
        }).catch((err) => {
            toastr.error("Mitarbeiter kann aktuell nicht gelöscht werden")
        })
    }

    renderPages() {
        if (this.state.count) {
            return (
                <>
                    {this.state.page > 2 && <i className="fa fa-angle-double-left" onClick={() => this.changePage(1)}></i>}
                    {this.state.page > 1 && <i className="fa fa-angle-left" onClick={() => this.changePage(this.state.page - 1)}></i>}
                    {this.state.page - 1 > 0 && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(this.state.page - 1)}>{this.state.page - 1}</span>}
                    <span className="active">{this.state.page}</span>
                    {this.state.page + 1 <= Math.ceil(this.state.count / this.limit) && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(this.state.page + 1)}>{this.state.page + 1}</span>}
                    {this.state.page + 2 < Math.ceil(this.state.count / this.limit) && <span>{'...'}</span>}
                    {this.state.page + 1 < Math.ceil(this.state.count / this.limit) && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(Math.ceil(this.state.count! / this.limit))}>{Math.ceil(this.state.count / this.limit)}</span>}
                    {this.state.page < Math.ceil(this.state.count / this.limit) && <i className="fa fa-angle-right" onClick={() => this.changePage(this.state.page + 1)}></i>}
                    {this.state.page + 1 < Math.ceil(this.state.count / this.limit) && <i className="fa fa-angle-double-right" onClick={() => this.changePage(Math.ceil(this.state.count! / this.limit))}></i>}
                </>
            )
        }
        return
    }

    changePage(page: number) {
        this.setState({page, loading: true}, () => {
            this.getUser()
        })
    }
})