import * as React from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

export interface DashboardProps extends RouteComponentProps {
    userRole: 'admin' | 'manager'
}

export interface ShortLink {
    link: string,
    title: string
}

const ManagerShortLinks: ShortLink[] = [
    {
        link: '/add-customer',
        title: 'Unternehmen anlegen'
    },
    {
        link: '/add-workout',
        title: 'Workout anlegen'
    },
    {
        link: '/add-meditation',
        title: 'Meditation anlegen'
    },
    {
        link: '/add-news',
        title: 'News anlegen'
    },
    {
        link: '/add-recipe',
        title: 'Rezept anlegen'
    },
    {
        link: '/qr',
        title: 'Partnerübersicht'
    }
]

const AdminShortLinks: ShortLink[] = [
    {
        link: '/add-challenge',
        title: 'Challenge anlegen'
    },
    {
        link: '/add-events',
        title: 'Events anlegen'
    }
]

export default withRouter(class Dashboard extends React.Component<DashboardProps, {}> {

    render() {
        let shortLinks: ShortLink[] = this.props.userRole === 'admin' ? AdminShortLinks : ManagerShortLinks
        return (
            <div className="main">
                <div className="row">
                    {/* 
                    <div className="col-lg-6 col-md-12">
                        <div className="dashboardcard">
                            <div className="title">
                                <h6>App Nutzer Statistik</h6>
                                <canvas id="chart"></canvas>
                            </div>
                        </div>
                    </div> */}

                    {/*                     <div className="col-lg-6 col-md-12">
                        <div className="dashboardcard">
                            <div className="title">
                                <h6>Kunden Statistik</h6>
                                <canvas id="chart_nutzer"></canvas>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-md-6">
                        <div className="title">
                            <h6>Schnellzugriff</h6>
                            <div className="row">
                                {shortLinks.map((val) => {
                                    return this.renderShortlink(val)
                                })}
                            </div>
                        </div>
                    </div>

                    {/*                     <div className="col-xl-6 col-lg-6">
                        <div className="dashboardcard">
                            <div className="title">
                                <img src="assets/images/calendericon.png" />
                                <h6>Eventkalender</h6>
                                <div className="control">
                                    <button onClick={() => {}}>&lsaquo;</button>
                                    <h5></h5>
                                    <button onClick={() => {}}>&rsaquo;</button>
                                </div>
                                <div id="calendar"></div>
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        )
    }

    renderShortlink(val: ShortLink) {
        return (
            <div className="col-6" key={val.title}>
                <div className="shortlink">
                    <Link to={val.link}>{val.title}</Link>
                </div>
            </div>
        )
    }
})
