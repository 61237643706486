import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { API } from '../api/endpoints/login';
import { Session } from '../api/requests/request';
import { LOGO } from '../assets';
import { getDeviceData } from '../utils/authentication_utils';
import { validateEmail } from '../utils/helper';
import { UserSession } from '../utils/session';
import toastr from 'toastr'

interface LoginProps extends RouteComponentProps {

}

export default withRouter(class Login extends React.Component<LoginProps, {}> {

    private emailInput: HTMLInputElement | null = null
    private pwInput: HTMLInputElement | null = null

    render() {

        return (
            <div className="login_container">
                <div className="logo_login">
                    <img src={LOGO} />
                </div>

                <div className="content_login">
                    <div className="image" />
                    <div className="textbox">
                        <div className="text">
                            <h1>Login</h1>
                            <p>Behalten Sie als Unternehmen den Überblick über Ihre Mitarbeiter und Lizenzen.</p>
                            <input ref={(ref) => this.emailInput = ref} placeholder="Benutzername / Email Adresse" type="email" />
                            <input ref={(ref) => this.pwInput = ref} placeholder="Passwort" type="password" className="password" />
                            <button onClick={() => this.login()} className="loginbutton">Login</button>
                            <Link to='/forgot-password'>{"Passwort vergessen"}</Link>
                        </div>
                    </div>
                </div>

                <div className="copyright_login">
                    Copyright © 2021 by FITJO
                </div>
            </div>
        )
    }

    login() {
        let email = this.emailInput?.value
        let pw = this.pwInput?.value
        if (email && validateEmail(email) && pw && pw.length > 0) {
            getDeviceData().then((deviceData) => {
                API.login(email!, pw!, deviceData).then((device) => {
                    Session.setSession(device.session_token)
                    UserSession.getSessionUpdatedHandler()({
                        user: device.User!,
                        device
                    })
                    this.props.history.push("/dashboard")
                }).catch((err) => {
                    toastr.error("Der Login war nicht erfolgreich, bitte überprüfe deine email-Adresse und dein Passwort")
                })
            }).catch((err) => {
                toastr.error("Entschuldige, das klappt leider gerade nicht.")
            })
        } else {
            toastr.warning("Ungültige email-Adresse oder ungültiges Passwort")
        }
    }
})
