import { JsonProperty } from "../requests/jsonparser";
import { BaseModel } from "./base";
export class QrCode extends BaseModel {

    @JsonProperty({ type: 'string' })
    name: string;

    @JsonProperty({ type: 'string' })
    description: string;

    @JsonProperty({ type: 'number' })
    points: number;

    @JsonProperty({ type: 'string' })
    qr: string;

    @JsonProperty({type: 'string'})
    partner_id: string;

}