import {APIVersion, AuthenticatedRequest, HTTPMethod} from "../requests/request";
import {QrCode} from "../models/qrCode";


export class QrAPI {
    public static getAllQr(limit?: number, offset?: number): Promise<QrCode[]> {
        let apiReqest = new AuthenticatedRequest<QrCode>('/qrCodes', HTTPMethod.GET, APIVersion.v1_0, {
            limit,
            offset
        });
        console.log("api all qr")
        console.log(apiReqest);
        return apiReqest.send(QrCode).then((response) => {
            console.log(response);
            return <QrCode[]>response.data
        })
    }

    public static getQr(id: string): Promise<QrCode[]> {
        let apiReqest = new AuthenticatedRequest<QrCode>(`/qrCodes/${id}`, HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data;
        })
    }

    public static uploadQr(qrcodes: QrCode[]) {
        let apiRequest = new AuthenticatedRequest<QrCode>('/qr/upload', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            qrcodes
        });
        return apiRequest.send(QrCode).then((response) => {
            return response.data;
        })
    }

    public static deleteQr(qrcodes: QrCode[]) {
        qrcodes.forEach(element => {
            let apiRequest = new AuthenticatedRequest<QrCode>(`/qrCodes/${element.id}`, HTTPMethod.DELETE, APIVersion.v1_0, undefined, {
            });
            return apiRequest.send().then((response) => {
                return response.data;
            });
        });

    }
}